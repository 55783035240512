:root {
  --color-primary: #1a1a1a;
  --color-secondary: #4d4d4d;
  --color-shadow: #0006;
  --color-tertiary: #b3b3b3;
  --color-white: #fff;
}

*, :after, :before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

input, textarea, button, select, a, .menu {
  -webkit-tap-highlight-color: #0000;
}

html {
  font-size: 10px;
}

body {
  background-color: var(--color-primary);
  min-width: 320px;
  font-family: Oxanium, cursive;
  overflow-x: hidden;
}

.header {
  backdrop-filter: blur(3px);
  box-shadow: 0 2px 2px 2px var(--color-shadow);
  pointer-events: none;
  text-align: center;
  z-index: 1000;
  background-color: #000000bf;
  justify-content: space-between;
  width: 100vw;
  height: 100px;
  padding: 1rem 5rem 1rem 2rem;
  display: flex;
  position: fixed;
}

#header__logo {
  pointer-events: auto;
  transition: all .3s;
}

.logo__icon {
  filter: drop-shadow(1px 5px 3px #000000d9);
  max-width: 8rem;
  height: 100%;
}

.logo__icon--footer {
  height: 60px;
}

.certifications__desktop {
  visibility: hidden;
  gap: 20px;
  display: flex;
}

.certifications__mobile {
  backdrop-filter: grayscale();
  z-index: 200;
  background-color: #000000a6;
  justify-content: space-around;
  width: 100%;
  padding-top: 110px;
  padding-bottom: .75rem;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.certifications__mobile > img {
  width: auto;
  max-width: 90px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (width >= 375px) {
  .logo__icon {
    max-width: 10rem;
  }
}

@media screen and (width >= 500px) {
  .certifications__mobile {
    visibility: hidden;
  }

  .certifications__desktop {
    visibility: visible;
  }
}

@media screen and (width >= 1024px) {
  .header {
    padding: .75rem 8rem .75rem 2rem;
  }

  .logo__icon--footer {
    height: 60px;
    margin-top: 2rem;
  }

  .logo__icon {
    max-width: 12rem;
  }
}

.menu {
  cursor: pointer;
  pointer-events: auto;
  flex-direction: column;
  justify-content: space-between;
  height: 2rem;
  display: flex;
  position: absolute;
  top: 30%;
  right: 1rem;
}

.menu__bar {
  background-color: var(--color-white);
  box-shadow: 0 3px 3px var(--color-shadow);
  border-radius: 5px;
  width: 35px;
  height: 5px;
  transition: all .5s;
  display: block;
}

.menu__bar:first-child.change {
  transform: rotate(-45deg)translateX(-.2rem)translateY(1rem);
}

.menu__bar:nth-child(2).change {
  opacity: 0;
}

.menu__bar:nth-child(3).change {
  transform: rotate(45deg)translateX(-.2rem)translateY(-1rem);
}

.sidebar {
  background-color: var(--color-primary);
  box-shadow: -5px 0 10px 5px var(--color-shadow);
  text-align: right;
  visibility: hidden;
  z-index: 900;
  width: 0;
  height: 100vh;
  transition: all 1s;
  position: fixed;
  top: 0;
  right: 0;
  overflow-x: hidden;
}

.sidebar.change {
  visibility: visible;
  width: 320px;
}

.sidebar__list {
  flex-direction: column;
  margin-top: 90px;
  padding-left: 0;
  font-size: 1.5rem;
  list-style: none;
  display: flex;
}

.sidebar__item {
  border-bottom: 1px solid var(--color-secondary);
  height: 4rem;
  overflow: hidden;
}

.sidebar__item a {
  color: var(--color-tertiary);
  text-transform: uppercase;
  min-width: 320px;
  padding-right: 2rem;
  line-height: 4rem;
  text-decoration: none;
  transition: all 1s;
  display: block;
}

.article {
  color: var(--color-white);
}

.article__showcase {
  text-shadow: 1px 1px 8px #000;
  text-transform: uppercase;
  z-index: 100;
  height: 100vh;
  transition: height .3s;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.article__showcase.change {
  height: 70vh;
}

.showcase__description {
  text-transform: uppercase;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding-bottom: 3rem;
  padding-left: 1.5rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.showcase__title {
  max-width: 80vw;
  font-size: 1.75rem;
}

.showcase__button {
  border: 1px solid var(--color-white);
  box-shadow: 0 0 10px 3px var(--color-shadow);
  color: var(--color-white);
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  background-color: #00000080;
  border-radius: 5px;
  max-width: fit-content;
  padding: .75rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 100%;
  transition: all .3s;
  display: inline-block;
}

.showcase__button--close {
  text-align: center;
  border-radius: 50%;
  min-width: 35px;
  height: 35px;
  margin: 0 auto;
  padding: .5rem;
  display: block;
}

.article__showcase video {
  pointer-events: none;
  z-index: -1000;
  width: 178vh;
  min-width: 100%;
  height: 100%;
  min-height: 56.25vw;
  position: absolute;
  top: 50%;
  left: -25%;
  transform: translate(-25%, -50%);
}

.article__details {
  box-shadow: inset 1px 4px 9px -1px var(--color-shadow);
  opacity: 0;
  visibility: hidden;
  height: 0;
  padding: 0;
  transition: all .5s;
  display: none;
}

.article__details.change {
  opacity: 1;
  visibility: visible;
  height: auto;
  padding: 2rem 1.25rem;
  display: block;
}

.details__container {
  grid-template-rows: fit-content(100%) fit-content(100%);
  grid-template-columns: 100%;
  display: grid;
}

.details__sub {
  margin-bottom: 2rem;
}

.details__sub p {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  line-height: 1.75;
}

.details__title {
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.details__title:first-child {
  margin-top: 0;
}

.details__table {
  table-layout: fixed;
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.details__table, th, td {
  border-collapse: collapse;
  word-break: break-word;
  border: 1px solid #e6e6e6;
  padding: 1rem;
}

.details__table thead {
  color: var(--color-primary);
  text-transform: uppercase;
  background-color: #e6e6e6;
}

.details__table tr:nth-child(2n) {
  background-color: #262626;
}

.details__table caption {
  caption-side: bottom;
  margin-top: .5rem;
  margin-bottom: 2rem;
  font-style: italic;
}

.download-button {
  background-color: var(--color-white);
  color: var(--color-primary);
  text-align: center;
  border-radius: 5px;
  max-width: fit-content;
  margin: 0 auto;
  padding: 1rem;
  transition: all .3s;
  display: block;
}

.download-button__icon {
  pointer-events: none;
  margin-right: .5rem;
}

.gallery {
  grid-gap: 1.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  margin-bottom: 2rem;
  display: grid;
}

.details__gallery {
  max-width: 100%;
  margin: 0 auto;
}

.gallery__item {
  border: none;
  border-radius: 10px;
  width: 100%;
}

@media screen and (width >= 500px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (width >= 768px), (orientation: landscape) {
  .article__showcase video {
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .showcase__title {
    max-width: 400px;
  }

  .details__container {
    grid-template-rows: fit-content(100%);
    grid-template-columns: 1fr 2fr;
  }

  .details__sub {
    padding: 0 1rem;
  }
}

@media screen and (width >= 1024px) {
  .menu {
    right: 2rem;
  }

  .details__sub {
    padding: 0 1.5rem;
  }

  .gallery {
    grid-template-columns: repeat(4, 1fr);
  }
}

.footer {
  box-shadow: inset 1px 4px 9px -1px var(--color-shadow);
  color: var(--color-white);
  text-align: center;
  text-transform: uppercase;
  min-height: 10vh;
  padding: 1rem;
  font-size: 1.2rem;
  position: relative;
  overflow: hidden;
}

.footer__image {
  width: 100vw;
  margin: 0 auto;
}

.footer__section {
  max-width: 280px;
  margin: 0 auto 3.5rem;
}

.footer__section:last-of-type {
  margin-bottom: 1rem;
}

.footer__title {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 2rem;
}

.contact__container {
  font-style: normal;
}

.contact__data {
  justify-content: center;
  align-items: center;
  margin-bottom: .75rem;
  display: flex;
}

.contact__icon {
  min-width: 32px;
  margin-right: 1rem;
  font-size: 2rem;
}

.social__icons {
  justify-content: space-around;
  max-width: 200px;
  margin: 0 auto;
  font-size: 2.5rem;
  display: flex;
}

.member__logos {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 200px;
  margin: 0 auto;
  display: flex;
}

.copyright {
  margin: 1rem auto;
  font-size: 1.5rem;
}

.developer {
  margin-top: 2rem;
  font-size: 1rem;
}

@media screen and (width >= 1024px) {
  .footer {
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    min-height: 40vh;
    padding: .5rem;
    display: grid;
  }

  .footer__section {
    min-width: 250px;
    max-width: 400px;
    margin-bottom: 1rem;
  }
}

@media screen and (hover: hover) {
  .sidebar__item a:hover {
    border-bottom: 3px solid var(--color-white);
    color: var(--color-white);
  }

  .showcase__button:hover {
    background-color: var(--color-primary);
    transform: scale(1.05);
  }

  .download-button:hover {
    transform: scale(1.03);
  }

  .footer a {
    transition: all .3s;
  }

  .footer a:hover {
    color: var(--color-tertiary);
    transform: scale(1.05);
  }

  .footer div.social__icons > a:hover {
    transform: scale(1.2);
  }
}
/*# sourceMappingURL=index.dafed038.css.map */
