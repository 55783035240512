:root {
  /* VARIABLES */

  /* COLORS */
  --color-primary: hsl(0, 0%, 10%);
  --color-secondary: hsl(0, 0%, 30%);
  --color-shadow: hsla(0, 0%, 0%, 0.4);
  --color-tertiary: hsl(0, 0%, 70%);
  --color-white: hsl(240, 100%, 100%);
}

/* RESETS */
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

input,
textarea,
button,
select,
a,
.menu {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* TYPOGRAPHY */
html {
  /* 62.5% of 16px is 10px to make calculations easier */
  font-size: 10px;
}

body {
  background-color: var(--color-primary);
  font-family: "Oxanium", cursive;
  min-width: 320px;
  overflow-x: hidden;
}

/* HEADER */
.header {
  backdrop-filter: blur(3px);
  background-color: hsla(0, 0%, 0%, 0.75);
  box-shadow: 0 2px 2px 2px var(--color-shadow);
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 1rem 5rem 1rem 2rem;
  pointer-events: none;
  position: fixed;
  text-align: center;
  width: 100vw;
  z-index: 1000;
}

#header__logo {
  pointer-events: auto;
  transition: all 0.3s;
}

.logo__icon {
  filter: drop-shadow(1px 5px 3px hsla(0, 0%, 0%, 0.85));
  height: 100%;
  max-width: 8rem;
}

.logo__icon--footer {
  height: 60px;
}

.certifications__desktop {
  display: flex;
  gap: 20px;
  visibility: hidden;
}

.certifications__mobile {
  backdrop-filter: grayscale(100%);
  background-color: hsla(0, 0%, 0%, 0.65);
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  padding-bottom: 0.75rem;
  padding-top: 110px;
  position: absolute;
  width: 100%;
  z-index: 200;
}

.certifications__mobile > img {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 90px;
  width: auto;
}

@media screen and (min-width: 375px) {
  .logo__icon {
    max-width: 10rem;
  }
}

@media screen and (min-width: 500px) {
  .certifications__mobile {
    visibility: hidden;
  }

  .certifications__desktop {
    visibility: visible;
  }
}

@media screen and (min-width: 1024px) {
  .header {
    padding: 0.75rem 8rem 0.75rem 2rem;
  }

  .logo__icon--footer {
    height: 60px;
    margin-top: 2rem;
  }

  .logo__icon {
    max-width: 12rem;
  }
}

/* MENU ICON */
.menu {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 2rem;
  justify-content: space-between;
  pointer-events: auto;
  position: absolute;
  right: 1rem;
  top: 30%;
}

.menu__bar {
  background-color: var(--color-white);
  border-radius: 5px;
  box-shadow: 0 3px 3px var(--color-shadow);
  display: block;
  height: 5px;
  transition: all 0.5s;
  width: 35px;
}

.menu__bar:nth-child(1).change {
  transform: rotate(-45deg) translateX(-0.2rem) translateY(1rem);
}

.menu__bar:nth-child(2).change {
  opacity: 0;
}

.menu__bar:nth-child(3).change {
  transform: rotate(45deg) translateX(-0.2rem) translateY(-1rem);
}

/* SIDEBAR NAV */

.sidebar {
  background-color: var(--color-primary);
  box-shadow: -5px 0 10px 5px var(--color-shadow);
  height: 100vh;
  overflow-x: hidden;
  position: fixed;
  right: 0;
  text-align: right;
  top: 0;
  transition: all 1s;
  visibility: hidden;
  width: 0;
  z-index: 900;
}

.sidebar.change {
  visibility: visible;
  width: 320px;
}

.sidebar__list {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  list-style: none;
  margin-top: 90px;
  padding-left: 0;
}

.sidebar__item {
  border-bottom: 1px solid var(--color-secondary);
  height: 4rem;
  overflow: hidden;
}

.sidebar__item a {
  color: var(--color-tertiary);
  display: block;
  line-height: 4rem;
  min-width: 320px;
  padding-right: 2rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 1s;
}

/* MAIN CONTENT SECTIONS */
.article {
  color: var(--color-white);
}

.article__showcase {
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: relative;
  text-shadow: 1px 1px 8px black;
  text-transform: uppercase;
  top: 0;
  transition: height 0.3s;
  z-index: 100;
}

.article__showcase.change {
  height: 70vh;
}

.showcase__description {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  padding-bottom: 3rem;
  padding-left: 1.5rem;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  width: 100%;
}

.showcase__title {
  font-size: 1.75rem;
  max-width: 80vw;
}

.showcase__button {
  background-color: hsla(0, 0%, 0%, 0.5);
  border: 1px solid var(--color-white);
  border-radius: 5px;
  box-shadow: 0 0 10px 3px var(--color-shadow);
  color: var(--color-white);
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  line-height: 100%;
  max-width: fit-content;
  padding: 0.75rem 1rem;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s;
}

.showcase__button--close {
  border-radius: 50%;
  display: block;
  height: 35px;
  margin: 0 auto;
  min-width: 35px;
  padding: 0.5rem;
  text-align: center;
}

.article__showcase video {
  height: 100%;
  left: -25%;
  min-height: 56.25vw;
  min-width: 100%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-25%, -50%); /* % of current element */
  width: 178vh;
  z-index: -1000;
}

.article__details {
  box-shadow: inset 1px 4px 9px -1px var(--color-shadow);
  display: none;
  height: 0;
  opacity: 0;
  padding: 0;
  transition: 0.5s ease;
  visibility: hidden;
}

.article__details.change {
  display: block;
  height: auto;
  opacity: 1;
  padding: 2rem 1.25rem;
  visibility: visible;
}

.details__container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: fit-content(100%) fit-content(100%);
}

.details__sub {
  margin-bottom: 2rem;
}

.details__sub p {
  font-size: 1.1rem;
  line-height: 1.75;
  margin-bottom: 1rem;
}

.details__title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.details__title:nth-child(1) {
  margin-top: 0;
}

.details__table {
  border-radius: 5px;
  margin: 0 auto;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}

.details__table, th, td {
  border: 1px solid hsl(0, 0%, 90%);
  border-collapse: collapse;
  padding: 1rem;
  word-break: break-word;
}

.details__table thead {
  background-color: hsl(0, 0%, 90%);
  color: var(--color-primary);
  text-transform: uppercase;
}

.details__table tr:nth-child(even) {
  background-color: hsl(0, 0%, 15%);
}

.details__table caption {
  caption-side: bottom;
  font-style: italic;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
}

.download-button {
  background-color: var(--color-white);
  border-radius: 5px;
  color: var(--color-primary);
  display: block;
  margin: 0 auto;
  max-width: fit-content;
  padding: 1rem;
  text-align: center;
  transition: all 0.3s;
}

.download-button__icon {
  margin-right: 0.5rem;
  pointer-events: none;
}

.gallery {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin-bottom: 2rem;
}

.details__gallery {
  margin: 0 auto;
  max-width: 100%;
}

.gallery__item {
  border: none;
  border-radius: 10px;
  width: 100%;
}

@media screen and (min-width: 500px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 768px), (orientation: landscape) {
  .article__showcase video {
    left: 50%;
    transform: translate(-50%, -50%); /* % of current element */
  }

  .showcase__title {
    max-width: 400px;
  }

  .details__container {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: fit-content(100%);
  }

  .details__sub {
    padding: 0 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .menu {
    right: 2rem;
  }

  .details__sub {
    padding: 0 1.5rem;
  }

  .gallery {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* FOOTER */
.footer {
  box-shadow: inset 1px 4px 9px -1px var(--color-shadow);
  color: var(--color-white);
  font-size: 1.2rem;
  min-height: 10vh;
  overflow: hidden;
  padding: 1rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.footer__image {
  margin: 0 auto;
  width: 100vw;
}

.footer__section {
  margin: 0 auto 3.5rem;
  max-width: 280px;
}

.footer__section:last-of-type {
  margin-bottom: 1rem;
}

.footer__title {
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.contact__container {
  font-style: normal;
}

.contact__data {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0.75rem;
}

.contact__icon {
  font-size: 2rem;
  margin-right: 1rem;
  min-width: 32px;
}

.social__icons {
  display: flex;
  font-size: 2.5rem;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 200px;
}

.member__logos {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  max-width: 200px;
}

.copyright {
  font-size: 1.5rem;
  margin: 1rem auto;
}

.developer {
  font-size: 1rem;
  margin-top: 2rem;
}

@media screen and (min-width: 1024px) {
  .footer {
    align-items: start;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    min-height: 40vh;
    padding: 0.5rem;
  }

  .footer__section {
    margin-bottom: 1rem;
    max-width: 400px;
    min-width: 250px;
  }
}

@media screen and (hover: hover) {
  .sidebar__item a:hover {
    border-bottom: 3px solid var(--color-white);
    color: var(--color-white);
  }

  .showcase__button:hover {
    background-color: var(--color-primary);
    transform: scale(1.05);
  }

  .download-button:hover {
    transform: scale(1.03);
  }

  .footer a {
    transition: all 0.3s;
  }

  .footer a:hover {
    color: var(--color-tertiary);
    transform: scale(1.05);
  }

  .footer div.social__icons > a:hover {
    transform: scale(1.2);
  }
}
